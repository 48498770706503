import { useEffect, useRef, useState } from 'react';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { isSampleValid, reportDuplicateSamples } from '../invalidScenarios';
import {
  getMetadataAttributeValues,
  getSampleAttributeValues,
  getSampleColorSpecValue,
  normalizeImageUrl
} from '../helpers';
import { SAFE_PRODUCTS_LIMIT } from '../constants';

export const useMultipleSamples = ({ parentId }) => {
  const { storeId, storeZip: zipCode } = useStore();

  const anchorItemIdsRef = useRef([]);
  const sampleProductsRef = useRef([]);
  const [currentSamplePage, setCurrentSamplePage] = useState(-1);
  const sampleItemIdsRef = useRef([]);
  const [response, setResponse] = useState({
    data: [],
    loading: true,
    sharedDimension: ''
  });

  const { data, loading: metadataLoading } = useDataModel('metadata', {
    fetchPolicy: 'cache-first',
    skip: !parentId,
    ssr: false,
    variables: {
      parentId
    }
  });
  const { metadata } = data || {};
  // TODO: Maybe we can make this a searchModel call? Would need to see what data differences are between datasources
  const [sampleRequest, samplesResponse] = useLazyDataModel('sampleProducts', { fetchPolicy: 'cache-first' });

  // Set Sample Ids
  useEffect(() => {
    if (metadata?.sampleProductDetail) {
      const anchorItemIds = [...new Set(metadata.childItemsLookup.map((childItem) => childItem.itemId))];
      if (anchorItemIds.length) {
        anchorItemIdsRef.current = anchorItemIds;
      }

      const sampleItemIds = [...new Set(metadata.sampleProductDetail.map((sampleProduct) => sampleProduct.sampleId))];
      if (sampleItemIds.length) {
        sampleItemIdsRef.current = sampleItemIds;
        setCurrentSamplePage(0);
      }
    }
  }, [metadata]);
  
  // Call Sample Products
  useEffect(() => {
    if (storeId && sampleItemIdsRef.current.length) {
      const pageStart = currentSamplePage * SAFE_PRODUCTS_LIMIT;
      const pageEnd = pageStart + SAFE_PRODUCTS_LIMIT;
      sampleRequest({
        variables: {
          itemIds: sampleItemIdsRef.current.slice(pageStart, pageEnd),
          storeId,
          zipCode
        }
      });
    }
  }, [currentSamplePage]);

  // Set Sample Products
  useEffect(() => {
    if (samplesResponse?.data?.products) {
      sampleProductsRef.current = [...sampleProductsRef.current, ...samplesResponse.data.products];
      const totalSamplePages = Math.ceil(sampleItemIdsRef.current.length / SAFE_PRODUCTS_LIMIT) - 1;
      if (currentSamplePage !== totalSamplePages) {
        setCurrentSamplePage(currentSamplePage + 1);
      } else {
        const attributes = metadata?.attributes || [];
        const skuNames = getMetadataAttributeValues(attributes);
        
        // Filtering out samples with bad data
        const validSamples = sampleProductsRef.current
          .filter((sample) => isSampleValid({
            anchorItemIds: anchorItemIdsRef.current,
            attributes,
            sample,
            skuNames
          }));

        const orderSamples = validSamples.sort((sampleA, sampleB) => {
          // Should sort in the same order as the super-sku attributes are given
          if (skuNames?.length) {
            const specValueA = getSampleColorSpecValue(attributes, sampleA.specificationGroup);
            const specValueB = getSampleColorSpecValue(attributes, sampleB.specificationGroup);
            
            const attributeIndexA = skuNames.indexOf(specValueA);
            const attributeIndexB = skuNames.indexOf(specValueB);

            // Show "invalid" samples at the bottom of the drawer
            if (attributeIndexA === -1) {
              if (attributeIndexB === -1) {
                return 0;
              }
              return 1;
            }
            if (attributeIndexB === -1) {
              return -1;
            }
              
            // resume normal sorting behavior
            if (attributeIndexA < attributeIndexB) {
              return -1;
            }
            if (attributeIndexA === attributeIndexB) {
              return 0;
            }
            return 1;
          }
          return 0;
        }).map((product) => {
          const {
            availabilityType, fulfillment, identifiers, media, pricing, specificationGroup
          } = product;
          const sampleSkuName = getSampleColorSpecValue(attributes, specificationGroup);
          const attributesSlice = attributes.slice(0, 2);
          const name = getSampleAttributeValues({
            specificationGroup,
            attributes:
              attributesSlice
          });

          const imageObject = media?.images.find((image) => image.subType === 'PRIMARY');
          const imageSize = imageObject?.sizes.find((size) => size === '400');
          const isFulfillable = (fulfillment?.fulfillmentOptions || [])
            .some((option) => option.fulfillable);

          return {
            itemId: identifiers.itemId,
            name,
            imgUrl: normalizeImageUrl(imageObject?.url, imageSize),
            inStockStatus: isFulfillable,
            skuName: sampleSkuName,
            discontinued: !!availabilityType?.discontinued,
            price: pricing?.value
          };
        });

        // Get SkuNames with more than one sample listed
        const duplicateNames = orderSamples.reduce((dupeSkuList, sample, index, array) => {
          const { skuName, name } = sample;
          if (
            // Not running dupe-check logic on samples without skuNames
            // (meaning we're depending on generic color family)
            !!skuName
            // Checking against the name to include any additional details included in the name (like measurements)
            && array.indexOf(name) !== index && dupeSkuList.indexOf(name) < 0) {
            dupeSkuList.push(name);
          }
          return dupeSkuList;
        }, []);

        reportDuplicateSamples({ duplicateNames, orderSamples });

        // Only show one sample per SKU name - in-stock where possible
        const cleanedSamples = orderSamples.reduce((uniqueSamples, sample, index, array) => {
          if (duplicateNames.includes(sample.skuName)) {
            if (!uniqueSamples.find((uniqueSample) => uniqueSample.skuName === sample.skuName)) {
              const dupeSamples = array.filter((dupeSample) => dupeSample.skuName === sample.skuName);
              const discontinuedOrOOSSamples = dupeSamples.filter((discSample) => {
                return discSample.discontinued || !discSample.inStockStatus;
              });
              // If all duplicates are discontinued/OOS, prefer showing the OOS sample (if there is one)
              if (dupeSamples.length === discontinuedOrOOSSamples.length) {
                const sortedDiscOOSSamples = discontinuedOrOOSSamples.sort((sampleA, sampleB) => {
                  if (sampleA.discontinued && !sampleB.discontinued) {
                    return 1;
                  }
                  if (!sampleA.discontinued && sampleB.discontinued) {
                    return -1;
                  }
                  return 0;
                });
                uniqueSamples.push(sortedDiscOOSSamples[0]);
                // If some duplicates aren't discontinued/OOS, just show the first in-stock sample
              } else if (discontinuedOrOOSSamples.length > 0) {
                if (!(sample.discontinued || !sample.inStockStatus)) {
                  uniqueSamples.push(sample);
                }
              // If no duplicates are discontinued/OOS, just show the first possible sample
              } else {
                uniqueSamples.push(sample);
              }
            }
          } else {
            uniqueSamples.push(sample);
          }
          return uniqueSamples;
        }, []);

        // Set final array of Sample Products
        setResponse({
          data: cleanedSamples,
          loading: false
        });
      }
    } else if (
      // No metadata
      (!metadata && !metadataLoading)
      // OR no samples
      || (samplesResponse?.called && !samplesResponse?.data?.products && sampleItemIdsRef.current.length === 0)) {
      setResponse({
        loading: false
      });
    }
  }, [samplesResponse.data?.products, metadata]);
  
  return response;
};